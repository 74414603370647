import React from "react";
import "./HeroSection.css";
import "../App.css";
import { useAuth } from "../firebase-config";


function HeroSection() {
  const currentUser = useAuth();

  const RedirectToService = async () => {
    if (currentUser) {
      window.location.href = "/portfolio";
    } else {
      window.location.href = "/login";
    }
  };

  return (
    <div className="hero-container">
      <img
        src="./images/homepage_background.jpeg"
        alt="Background for Hero Container"
      />
      <h1>Start Tracking your Networth Accurately!</h1>
      <div className="hero-btns">
        <button
          type="button"
          className="btn btn-light"
          onClick={RedirectToService}
        >
          Get Started
        </button>

        <button type="button" className="btn btn-light">
          Watch Tutorial <i className="far fa-play-circle" />
        </button>
      </div>
    </div>
  );
}

export default HeroSection;
