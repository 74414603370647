import React from 'react'

export const SidebarData = [
    {
        title: "Portfolio",
        icon: <i className="fa-solid fa-chart-pie"></i>,
        link: "/portfolio"
    },
    {
        title: "Dividend Breakdown",
        icon: <i className="fa-solid fa-money-check-dollar"></i>,
        link: "/portfolio/dividends"
    },
    {
        title: "Calculators",
        icon: <i className='fa-solid fa-calculator'></i>,
        link: "/portfolio/calculators"
    }
]