import React from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

export function TickerSelect(props) {
  const addStock = async (symbol, name, exchange) => {
    console.log(symbol + " " + name + " " + exchange.mic);

    const functions = getFunctions();
    const addStockToUser = httpsCallable(functions, "addStockToUser");
    addStockToUser({ ticker: `${symbol}`, name: `${name}`, exchange: `${exchange.mic}` }).then((result) => {
      console.log("stage 1")
      props.childToParent(true)
    });
  };
  return (
    <div class="list-group p-2">
      {props.datapass?.slice(0, 5).map((val, key) => {
        return (
          <ul key={key} className="list-group">
            <button
              type="button"
              class="btn btn-outline-dark m-1"
              onClick={() => addStock(val.symbol, val.name, val.stock_exchange)}
            >
              {val.symbol} - {val.name}
            </button>
          </ul>
        );
      })}
    </div>
  );
}
