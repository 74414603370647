import "../../App.css";
import HeroSection from "../HeroSection";
import React from "react";
import Navbar from "../Navbar";
import { Helmet } from "react-helmet";

const TITLE = "YourNetworth";

function Home() {
  return (
    <>
      <Navbar />
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HeroSection></HeroSection>
    </>
  );
}

export default Home;
