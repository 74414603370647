import "./Portfolio.css";

import React from "react";
import PortfolioNavbar from "../portfolio-components/PortfolioNavbar";
import { Helmet } from "react-helmet";

const TITLE = "Calculators";

function CalculatorView() {
  return (
    <div className="CalculatorView">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="row">
        <div className="sidebar col-md-auto">
          <PortfolioNavbar />
        </div>

        <div className="main-content col">
          <h2>CalculatorView Page To Be Constructed</h2>
          <h2>CalculatorView Page To Be Constructed</h2>
          <h2>CalculatorView Page To Be Constructed</h2>
          <h2>CalculatorView Page To Be Constructed</h2>
        </div>
      </div>
    </div>
  );
}

export default CalculatorView;
