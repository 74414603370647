import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import Home from "./components/pages/Home";
import Portfolio from "./components/pages/Portfolio";
import About from "./components/pages/About";
import Pricing from "./components/pages/Pricing";
import SignUp from "./components/pages/Sign-up";
import Login from "./components/pages/Login";
import DividendView from "./components/pages/DividendView";
import CalculatorView from "./components/pages/CalculatorView";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact element={Home()} />
          <Route path="/portfolio" exact element={Portfolio()} />
          <Route path="/about" exact element={About()} />
          <Route path="/pricing" exact element={Pricing()} />
          <Route path="/sign-up" exact element={SignUp()} />
          <Route path="/login" exact element={Login()} />
          <Route path="/portfolio/dividends" exact element={DividendView()} />
          <Route path="/portfolio/calculators" exact element={CalculatorView()}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
