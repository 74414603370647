import React from "react";
import { PortfolioGrid } from "./PortfolioGrid";

export default function PortfolioMain() {


  return (
    <div className="container main-content">
        <PortfolioGrid></PortfolioGrid>

    </div>
  );
}
