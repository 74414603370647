import "../../App.css";
import Navbar from "../Navbar";

import React from 'react'

function Home() {
  return (
    <>
      <Navbar/>
      <h2>About Page To Be Constructed</h2>
    </>
  );
}

export default Home;
