import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";



const firebaseConfig = {
  apiKey: "AIzaSyD5G5QflVFvmiqVuopLngWM5_-H8hm0UqU",
  authDomain: "y-nw-435d7.firebaseapp.com",
  projectId: "y-nw-435d7",
  storageBucket: "y-nw-435d7.appspot.com",
  messagingSenderId: "1020469659791",
  appId: "1:1020469659791:web:0f0c3596c49c19519d48fa",
  measurementId: "G-XNZEF4839P"
};

const app = initializeApp(firebaseConfig);

// eslint-disable-next-line
self.FIREBASE_APPCHECK_DEBUG_TOKEN = false;
// eslint-disable-next-line
const analytics = getAnalytics(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeL5MYgAAAAAJcDcmdPipfbFz41phyQ24MpUvdj'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
// eslint-disable-next-line
const functions = getFunctions(app);


export const auth = getAuth(app);

export function useAuth(){
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, user =>  setCurrentUser(user));
    return unsub;
  }, []) 

  return currentUser;
}

export default appCheck;