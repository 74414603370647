import React, { useEffect, useState } from "react";
import { TickerSelect } from "./TickerSelect";
import { getFunctions, httpsCallable } from "firebase/functions";

export function SearchTool(props) {
  const [query, setQuery] = useState("qqq");
  const [data, setData] = useState();

  const childToParent = (childData) => {
    console.log("Stage 2")
    console.log(childData)
    props.childToParent(childData);
  }

  // const [isLoading, setLoading] = useState(false);
  //   const [isError, setError] = useState(false);

  useEffect(() => {
    console.log({ query });
    //console.log({ BASEURL });
    const fetchData = async () => {
      //console.log(`${BASEURL}/search/${query}`);

      //console.log({ query });
      //   setError(false);
      //   setLoading(true);


      const functions = getFunctions();
      const authenticateStockRequest = httpsCallable(functions, "authenticateStockRequest");
      authenticateStockRequest({ query: `${query}` }).then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        // console.log(result)
        // console.log(JSON.parse(result.data).data)
        setData(JSON.parse(result.data).data);
      });

        // axios({
        //   method: "GET",
        //   url: `${stock_search.base_url}`, // or https://us-central1-PROJECT_NAME.cloudfunctions.net/test_message_json
        //   params: {
        //     query: `${query}`,
        //   },
        //   headers: { "Content-Type": "application/json" },
        // })
        //   .then((response) => {
        //     console.log(response.data);
        //     console.log(JSON.parse(response.data.body).data);
        //     setData(JSON.parse(response.data.body).data);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
        // setLoading(false);
    };

    fetchData();
  }, [query]);


  return (
    <>
      <button
        type="button"
        className="stockbtn btn btn-dark justify-content-center align-items-center"
        data-bs-toggle="modal"
        data-bs-target="#SearchModal"
      >
        Add Stock <i className="fa-solid fa-plus"></i>
      </button>

      <div
        className="modal fade"
        id="SearchModal"
        tabindex="-1"
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="ModalLabel">
                Search For Stocks
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    placeholder="Stock Ticker"
                    onChange={(event) => {
                      setQuery(event.target.value);
                    }}
                  ></input>
                  <label for="floatingInput">Stock Ticker</label>
                </div>
              </form>
            </div>

            <TickerSelect datapass={data} childToParent={childToParent} />
            {/* <div class="list-group p-2"></div> */}

            {/*  keeping this code in reserve just incase.
            <button type="button" class="list-group-item list-group-item-action btn btn-primary" data-toggle="button" aria-pressed="false">
                    The current button
                </button> */}

            <div className="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => window.location.reload(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
