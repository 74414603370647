import React from "react";
import { SidebarData } from "./SidebarData.js";

function PortfolioNavbar() {
  return (
    <div className="SideBar">
      <ul className="SideBarList">
        {SidebarData.map((val, key) => {
          return (
            <li
              key={key}
              className="SideBarRow"
              id={window.location.pathname === val.link ? "active" : ""}
              onClick={() => {
                window.location.pathname = val.link;
              }}
            >
              {" "}
              <div id="icon">{val.icon}</div>
              <div id="title">{val.title}</div>
            </li> 
          );
        })}
      </ul>    
    </div>
  );
}

export default PortfolioNavbar;
