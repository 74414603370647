import React from "react";
import { PortfolioGridItem } from "./PorfolioGridItem";

export function PortfolioGrid(props) {

  return (
    <table className="table">
        <thead>
            <tr>
                <th scope="col">Symbol</th>
                <th scope="col">Name</th>
                <th scope="col">Price</th>
                <th scope="col">Holding</th>
                <th scope="col">Value</th>
                <th scope="col">% Gain +/-</th>
            </tr>
        </thead>
          <PortfolioGridItem />
    </table>
  )
}
