import "../../App.css";

import React from 'react'

function Home() {
  return (
    <>
      <h2>Sign-up Page To Be Constructed</h2>
    </>
  );
}

export default Home;
