// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   solid,
//   regular,
//   brands,
// } from "@fortawesome/fontawesome-svg-core/import.macro";
// import {
//   faMoneyBillTrendUp,
//   faWindowMinimize,
// } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import { auth } from "../firebase-config";
import { signOut } from "firebase/auth";

function NavbarLogOut() {
  const logout = async () => {
    signOut(auth)
      .then(() => {
        window.location.href = "/";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
        <div className="container-fluid">
          <a className="navbar-brand" aria-current="page" href="/">
            YourNetworth.io <i className="fas fa-money-bill-trend-up"></i>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarColor01"
            aria-controls="navbarColor01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          className="collapse navbar-collapse m-2 p-auto order-2 dual-collapse2"
          id="navbarColor01"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/about" className="nav-link">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/pricing" className="nav-link">
                Pricing
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="#"
                className="nav-link"
                onClick={logout}
                id="liveToastBtn"
              >
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* Toast to confirm logout */}
{/* 
      <div
        class="position-fixed bottom-0 right-0 p-3"
      >
        <div
          id="liveToast"
          class="toast fade hide"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          data-delay="2000"
        >
          <div class="toast-header">
            <svg
              class="bd-placeholder-img rounded mr-2"
              width="20"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <rect width="100%" height="100%" fill="#007aff"></rect>
            </svg>

            <strong class="mr-auto">Bootstrap</strong>
            <small>11 mins ago</small>
            <button
              type="button"
              class="ml-2 mb-1 close"
              data-dismiss="toast"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="toast-body">Hello, world! This is a toast message.</div>
        </div>
      </div> */}
    </>
  );
}

export default NavbarLogOut;
