import "./Portfolio.css";

// import { auth } from "../../firebase-config";

import React, { useState } from "react";
import PortfolioNavbar from "../portfolio-components/PortfolioNavbar";
import PortfolioMain from "../portfolio-components/PortfolioMain";
import { Helmet } from "react-helmet";
import { SearchTool } from "../portfolio-components/search-tool/SearchTool";
import { Toast } from "react-bootstrap";
//import { ConfirmationAlert } from "../portfolio-components/search-tool/ConfirmationAlert";

//import { getDatabase } from "firebase/database";

//const database = getDatabase();

const TITLE = "Portfolio";

function Portfolio() {
  const [show, setShow] = useState(false);

  const childToParent = (childData) => {
    console.log("Stage 3");
    setShow(childData);
    console.log("Toast Shown");
  };

  return (
    <div className="Portfolio">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="row">
        <div className="sidebar col-md-auto">
          <PortfolioNavbar />
        </div>

        <div className="main-content col">
          {/* Header */}
          <div className="container">
            <div className="row justify-content-center p-2">
              <div className="col-auto">
                <div className="PortfolioHeading">
                  <h2 id="header">Portfolio</h2>
                </div>
              </div>
              <div className="col-auto p-0 justify-content-center align-items-center">
                <SearchTool childToParent={childToParent} />
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="contaner">
            <PortfolioMain />
            <div class="toast-container">
              <div class="position-absolute top-0 end-0 z-index:9999999999999">
                <Toast
                  onClose={() => setShow(false)}
                  show={show}
                  delay={5000}
                  autohide
                >
                  <Toast.Header>
                    <img src="..." class="rounded me-2" alt="..." />
                    <strong className="me-auto">Confirmation</strong>
                    <small>Now</small>
                  </Toast.Header>
                  <Toast.Body>Stock Added To Account Successfully!</Toast.Body>
                </Toast>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
