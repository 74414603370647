import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

import { auth } from "../firebase-config";

function SigninSection() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");

  const provider = new GoogleAuthProvider();

  const googleSignInPopup = async () => {
    try {
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          window.location.href = "/portfolio";
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // // The email of the user's account used.
          // const email = error.customData.email;
          // // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // // ...
        });
    } catch (error) {
      console.log("Google Sign in Failed!");
    }
  };

  const register = async () => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      console.log(user);

      if (user) {
        window.location.href = "/portfolio";
      } else {
        console.log("login failed");
        // registerSubmitButton.style.background = "red";
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      console.log(user);

      if (user) {
        window.location.href = "/portfolio";
      } else {
        console.log("login failed");
        // registerSubmitButton.style.background = "red";
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div class="container-fluid">
      <div class="position-absolute top-50 start-50 translate-middle">
        {/* Login */}
        <div class="float-md-center">
          <h3 class="bpv bpw text-center">Login</h3>
        </div>

        {/* Email Address input box */}
        <div class="mb-3">
          <label for="InputEmailLogin" class="form-label">
            Email address
          </label>
          <input
            type="email"
            class="form-control"
            id="InputEmailLogin"
            aria-describedby="emailHelp"
            onChange={(event) => {
              setLoginEmail(event.target.value);
            }}
          />
          <div id="emailHelp" class="form-text">
            We'll never share your email with anyone else.
          </div>
        </div>
        <div class="mb-3">
          <label for="InputPasswordLogin" class="form-label">
            Password
          </label>
          <input
            type="password"
            class="form-control"
            id="InputPasswordLogin"
            onChange={(event) => {
              setLoginPassword(event.target.value);
            }}
          />
        </div>

        <div class="row">
          <div class="col text-center">
            <button class="btn btn-primary  m-2" onClick={login}>
              Login
            </button>
            {/* Registering User */}

            <button
              class="btn btn-primary m-2"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRegister"
              aria-controls="offcanvasRegister"
            >
              Register
            </button>

            <div class="col align-self-center">
              <button class="btn btn-primary  m-2" onClick={googleSignInPopup}>
                Sign in with Google <i className="fa-brands fa-google" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Off Canvas Registration */}
      <div
        class="offcanvas offcanvas-start"
        tabindex="-3"
        id="offcanvasRegister"
        aria-labelledby="offcanvasRegisterLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasRegisterLabel ">
            Register
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div class="mb-3">
            <label for="InputRegisterEmail" class="form-label">
              Email address
            </label>
            <input
              type="email"
              class="form-control"
              id="InputRegisterEmail"
              aria-describedby="emailHelp"
              onChange={(event) => {
                setRegisterEmail(event.target.value);
              }}
            />
            <div id="emailHelp" class="form-text">
              We'll never share your email with anyone else.
            </div>
          </div>
          <div class="mb-3">
            <label for="InputPasswordRegister" class="form-label">
              Password
            </label>
            <input
              type="password"
              class="form-control"
              id="InputPasswordRegister"
              onChange={(event) => {
                setRegisterPassword(event.target.value);
              }}
            />
            <div class="col-12 text-center m-2">
              <button onClick={register} class="btn btn-primary">
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SigninSection;
