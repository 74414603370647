// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   solid,
//   regular,
//   brands,
// } from "@fortawesome/fontawesome-svg-core/import.macro";
// import {
//   faMoneyBillTrendUp,
//   faWindowMinimize,
// } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import NavbarLogOut from "./NavbarLogOut";
import NavbarLogIn from "./NavbarLogIn";
import { useAuth } from "../firebase-config";


function Navbar() {
  
  const currentUser = useAuth();
  
  if (currentUser) {
    console.log(currentUser)
    return <NavbarLogOut/>
  } else {
    console.log(currentUser)
    return <NavbarLogIn/>
  }

}

export default Navbar;
