import "../../App.css";
import "bootstrap/dist/css/bootstrap.css";

import SigninSection from "../SigninSection";
import Navbar from "../Navbar";

import React from "react";

function LoginPage() {
  return (
    <>
      <Navbar />

      <SigninSection />
    </>
  );
}

export default LoginPage;
