import React from "react";
import { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

export function PortfolioGridItem(props) {
  const [data, setData] = useState(null);
  const [priceData, setPriceData] = useState([]);
  
  

  const fetchPortfolio = async () => {
    const functions = getFunctions();
    const getPortfolio = httpsCallable(functions, "getPortfolio");
    getPortfolio().then((result) => {
      //console.log("Get Portfolio \n" + result.data + "\n" + typeof result.data);
      //const map = new Map(Object.entries(JSON.parse(result.data)));
      //console.log("Map: \n" + JSON.parse(result.data));

      setData(JSON.parse(result.data));
    });

  };

  useEffect(() => {
    fetchPortfolio();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(data){
      FindPrices(data)
    }
  }, [data]);

  //console.log("passed in data type: " + typeof data);
  //console.log(data);


  function FindPrices(stockData){
    let functionQuery = []
    const functions = getFunctions();
    const getPrices = httpsCallable(functions, "getPrices");

    //console.log(stockData)

    let arr = []
    arr = Object.entries(stockData);


    
    // eslint-disable-next-line
    (Object.entries(arr) || []).map(([key, value]) => {
      //console.log(Object.keys(value[1])[0])
      functionQuery.push(Object.keys(value[1])[0])
    })

    //console.log("functionQuery: " + functionQuery)

    getPrices({ query: `${functionQuery}`}).then((result) => {
      //console.log("Get Prices \n" + result.data + "\n" + typeof result.data);
      //const map = new Map(Object.entries(JSON.parse(result.data)));
      //console.log("Price Map: \n" + JSON.parse(result.data));
      setPriceData(JSON.parse(result.data))
    });

    //console.log(data);
  }

  //console.log(priceData)


  function CountHoldings(key, holdings){
    //console.log("Key: " + key + "\nHoldings: " + holdings + "\nHeadings: " + Object.entries(holdings))
    let arr = [];
    let summedArr = 0.0;
    arr = Object.entries(holdings);
    //console.log(arr[0][1].holdings[0]);

    // eslint-disable-next-line
    (Object.entries(arr[0][1].holdings[0]) || []).map(([key, value]) => {
      //console.log(value);
      //console.log(value[0]);
      summedArr = summedArr + value[0]
    })
    //console.log(arr);


    return summedArr
  }

  function FetchName(holdings){
    let name = '';
    // //console.log(holdings);

    // eslint-disable-next-line
    (Object.entries(holdings) || []).map(([key, value]) => {
      ////console.log(value.name);
      name = value.name
    })

    return name;
  }

  function ExtractTicker(holdings){
    let arr = []
    arr = Object.keys(holdings)
    //console.log(arr[0])
    return arr[0]
  }

  function ExtractPrice(holdings){
    let price = 0
    let ticker = ""
    ticker = Object.keys(holdings)[0]
    //console.log(ticker)

    //console.log(priceData?.data)

    if(priceData.data === undefined){
      //console.log("Data Unavailable")
      return "Fetching Data!"
    } else if (priceData.data !== undefined){
    // eslint-disable-next-line
      (Object.entries(priceData) || []).map(([key, value]) => {
        // eslint-disable-next-line
        (Object.entries(Object.entries(Object.entries(priceData)[1])) || []).map(([key, value]) => {
          //console.log("Deep Key ");
          //console.log(key);
          //console.log("Deep Value ");
          //console.log(value[1]);
          //console.log(value[1].length);
           // eslint-disable-next-line
          (Object.entries(value[1]) || []).map(([key, value]) => {
            //console.log("MEGA DEEEP")
            if(Object.entries(value).length>3){
               console.log(Object.entries(value));
              if(Object.entries(value)[7][1] === ticker){
                price = Object.entries(value)[3][1]
                if(price === null){
                  price = Object.entries(value)[0][1]
                }
              }
            }
          })


        })
        // //console.log(key);
        // //console.log(value)
        // //console.log(Object.entries(value));
        if (Object.keys(value)[0] === ticker){
          //console.log("Its a match")
        }
      })
    }

    return price;
  }

  if (!data) {
    return null;
  }
  
  function CalculateValue(holdings, price){
    return price*holdings;
  }

  return (
    <tbody>
      {(Object.entries(data) || []).map(([key, value]) => {
        return (
          <tr>
          <th scope="row">{ExtractTicker(value)}</th>
          <td id="Name">{FetchName(value)}</td>
          <td id="Price">{ExtractPrice(value)}</td>
          <td id="Holding">{CountHoldings(key, value)}</td>
          <td id="Price">{CalculateValue(CountHoldings(key, value), ExtractPrice(value))}</td>
        </tr>
        );
      })}
    </tbody>
  );
}
